import { Tooltip, Typography } from '@mui/material';
import { MealPhotoQueueResponse, MealResponse } from 'api/generated/MNT';
import { DateTime } from 'luxon';
import { useMealForQueue } from './useMealForQueue';

const getMealDiffString = (queueItem: MealPhotoQueueResponse, meal: MealResponse) => {
  const mealDate = DateTime.fromSQL(meal.meal_date + ' ' + meal.meal_time);
  const queueDate = DateTime.fromISO(queueItem.created_time);
  const diff = mealDate.diff(queueDate, 'hours');
  const diffInHours = Math.abs(+diff.toFormat('h'));
  const beforeAfter = mealDate < queueDate ? ' before queue' : ' after queue';
  const diffString = diffInHours < 12
    ? ''
    : diffInHours < 24
    ? diff.toFormat('h').replace('-', '') + ' hours' + beforeAfter
    : diff.toFormat('d').replace('-', '') + ' days' + beforeAfter;
  return diffString;
};

export const MealNameAndTimeForQueue = (props: {
  queueItem: MealPhotoQueueResponse,
}) => {
  const { queueItem } = props;

  const { meal } = useMealForQueue({ queueItem });

  if (!meal) {
    return <span>No meal associated</span>;
  }

  const diffString = getMealDiffString(queueItem, meal);

  return (
    <div>
      <Typography>
        <b>Meal:{' '}</b>
        <span>{meal?.meal_name}</span>
      </Typography>

      <Typography>
        <b>Meal time:{' '}</b>
        <span>{meal.meal_date} {meal.meal_time.substring(0, 5)}</span>
        {diffString && <i>{' '}({diffString})</i>}
      </Typography>
    </div>
  );
};

export const MealName = (props: {
  queueItem: MealPhotoQueueResponse,
}) => {
  const { meal } = useMealForQueue({ queueItem: props.queueItem });

  if (!meal) {
    return (
      <Tooltip
        title="(no meal associated)"
        arrow
      >
        <Typography>
          (none)
        </Typography>
      </Tooltip>
    );
  }

  return (
    <Typography>
      {meal.meal_name}
    </Typography>
  );
};

export const MealTimeCondensed = (props: {
  queueItem: MealPhotoQueueResponse,
}) => {
  const { meal } = useMealForQueue({ queueItem: props.queueItem });

  if (!meal) {
    return null;
  }

  const diffString = getMealDiffString(props.queueItem, meal);

  return (
    <Tooltip
      title={
        <span>
          {meal.meal_date} {meal.meal_time} {diffString && <i>({diffString})</i>}
        </span>
      }
      arrow
    >
      <Typography>
        {meal.meal_time.substring(0, 5)}
      </Typography>
    </Tooltip>
  );
};
