import { Button, ButtonGroup, Typography } from '@mui/material';
import { logTrackedError } from 'errorTracking';
import { useEffect, useState } from 'react';
import { humanFileSize } from 'utils/numerical';

const LOCAL_STORAGE_WARNING_THRESHOLD = 2 * 1024 * 1024;

export const getLocalStorageStats = () => {
  const keysAndSizes = Object.entries(localStorage).map(([key, value]) => ({
    key,
    size: value.length,
  }));
  keysAndSizes.sort((a, b) => b.size - a.size);
  const topKeysMsg = keysAndSizes.slice(0, 5).map(k => `${k.key}: ${humanFileSize(k.size)}`).join('; ');
  return {
    keysAndSizes,
    topKeysMsg,
    total: keysAndSizes.reduce((acc, { size }) => acc + size, 0),
  };
};

export const LocalStoragePopup = () => {
  const [showMessage, setShowMessage] = useState(false);

  const checkLocalStorageUsage = () => {
    const localStorageSize = getLocalStorageStats().total;
    if (localStorageSize > LOCAL_STORAGE_WARNING_THRESHOLD) {
      setShowMessage(true);
      logTrackedError({
        sourceName: 'LocalStoragePopup',
        origin: new Error(),
        stackError: new Error(),
        context: {
          localStorageSize: humanFileSize(localStorageSize),
          topKeys: getLocalStorageStats().topKeysMsg,
        },
        userMessage: `Local storage size exceeded 1MB.`,
        skipWindowAlert: true,
      });
    } else {
      setShowMessage(false);
    }
  };

  useEffect(() => {
    checkLocalStorageUsage();
    const checkLocalStorageInterval = setInterval(checkLocalStorageUsage, 5 * 60 * 60);

    return () => clearInterval(checkLocalStorageInterval);
  }, []);

  if (!showMessage) {
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '2vh',
        right: '2vh',
        zIndex: 1000,
        backgroundColor: 'white',
        border: '1px solid red',
        padding: 10,
        borderRadius: 10,
        maxWidth: '45vw',
      }}
    >
      <Typography fontWeight="bold">
        PLEASE DISABLE YOUR ADBLOCKER<br />
        <br />
      </Typography>
      <Typography>
        More local storage is being used than expected, which could be caused by an adblocker. <br />
        <br />
        We depend on telemetry collected by Mixpanel - a tool often blocked by adblockers - to improve the Auditor's
        features and performance.<br />
        <br />
        When adblockers are turned on, we aren't able to collect telemetry, which makes it more difficult to improve the
        auditor.<br />
        <br />
        If you have disabled adblockers and are still recieving this warning, please reach out to IT Support so they can
        get to the bottom of it.<br />
        <br />
        For debugging purposes:<br />
        {getLocalStorageStats().topKeysMsg}
      </Typography>
      <br />
      <ButtonGroup fullWidth variant="contained">
        <Button
          onClick={() => {
            if (!window.confirm('Are you sure you want to clear local storage?')) {
              return;
            }
            setShowMessage(false);
            localStorage.clear();
            window.location.reload();
          }}
        >
          Clear local storage (this will logout and refresh)
        </Button>
        <Button
          color="error"
          onClick={() => {
            setShowMessage(false);
          }}
        >
          Dismiss
        </Button>
      </ButtonGroup>
    </div>
  );
};
