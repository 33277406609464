import { useEffect } from 'react';
import { useAsyncResult } from 'react-use-async-result';

import * as _ from 'lodash';

import type { QualityAssuranceLogResponse } from 'api/generated/MNT';
import { getMealQueueItemQaLogs, type MealQueueItem } from 'apiClients/mpq';
import { useAuth } from 'context/appContext';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'App.css';
import { CreateQualityAssuranceLogRequest } from 'api/generated/MNT';
import { config } from 'config';

export const useMealQALogs = (queueItem: MealQueueItem) => {
  const { authInfo } = useAuth();
  const logsResult = useAsyncResult<QualityAssuranceLogResponse[]>();

  useEffect(() => {
    if (!(authInfo?.access_token && queueItem?.id)) {
      return;
    }

    logsResult.bind(getMealQueueItemQaLogs(authInfo.reviewer_id, queueItem.id));
  }, [queueItem, authInfo]);

  const postMealQA = async (
    access_token: string,
    params: {
      data_reviewer_id: number,
      meal_photo_queue_id: number,
    } & CreateQualityAssuranceLogRequest,
  ): Promise<QualityAssuranceLogResponse> => {
    const url =
      `${config.API_URL}/api/data_reviewer/${params.data_reviewer_id}/meal_photo_queue/${params.meal_photo_queue_id}/quality_assurance_log`;

    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${access_token}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(params),
    });

    if (response.status !== 200) {
      throw new Error(`${response.status}: ${await response.text()}`);
    }

    logsResult.bind(getMealQueueItemQaLogs(params.data_reviewer_id, params.meal_photo_queue_id));
    return response.json();
  };

  return {
    qaLogs: !logsResult.isDone || logsResult.isPending || logsResult.isError ? [] : logsResult.result,
    loading: logsResult.isPending,
    error: logsResult.isError,
    postMealQA,
  };
};
