import {
  Button,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  FormGroup,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment';

import type { MealResponse } from 'api/generated/MNT';
import { BlobImageHistory } from 'components/BlobImageHistory';
import MainCard from 'components/MainCard';
import { useAuth } from 'context/appContext';
import _ from 'lodash';
import React from 'react';
import { MixpanelMealItemSource } from 'types/DraftItem';

export const RecommendedMeals = (props: {
  title?: string,
  mealHistory: MealResponse[],
  mealTitles?: string[],
  size?: string,
  addItemSource: MixpanelMealItemSource,
  handleClick?: (mealResponse: MealResponse, index: number, source?: string) => void,
  showItemMenu?: boolean,
}) => {
  const { mealHistory } = props;
  const { authInfo } = useAuth();

  const [selectedItems, setSelectedItems] = React.useState<boolean[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [selectedMealId, setSelectedMealId] = React.useState<number | null>(null);

  return (
    <Stack>
      {props.title && (
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {props.title}
        </Typography>
      )}
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          overflowX: 'auto',
          overflowY: 'hidden',
          maxHeight: 400,
        }}
      >
        {mealHistory.map((mealResponse, index) => {
          if (!mealResponse?.meal_items?.length) {
            return null;
          }

          // Find the first meal item with a photo
          const mealItemWithPhoto = mealResponse.meal_items.find((mealItem) => Number.isFinite(mealItem.meal_photo_id));
          const mealTime = moment.utc(mealResponse.meal_items[0].created_time).fromNow();

          return (
            <MainCard
              key={mealResponse.id}
              sx={{
                minWidth: props.size == 'small' ? 150 : 200,
                maxWidth: props.size == 'small' ? 150 : 200,
                padding: 0,
              }}
              contentSX={{
                padding: 1,
                paddingBottom: '10px !important',
              }}
              className="click"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                if (props.showItemMenu) {
                  setAnchorEl(e.currentTarget);
                  setSelectedMealId(mealResponse.id);
                  setSelectedItems(new Array(mealResponse.meal_items.length).fill(true));
                } else {
                  props.handleClick?.(mealResponse, index, props.addItemSource);
                }
              }}
              onContextMenu={() => {
                window.open(`${window.location.origin}/meal/${mealResponse.id}`, '_blank');
              }}
            >
              {props.showItemMenu && selectedMealId === mealResponse.id && (
                <ClickAwayListener
                  onClickAway={() => {
                    setAnchorEl(null);
                    setSelectedMealId(null);
                    setSelectedItems([]);
                  }}
                >
                  <Popper
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    placement="bottom"
                    sx={{ zIndex: 11 }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Paper sx={{ p: 2, maxWidth: 200 }}>
                      <Stack spacing={0.5}>
                        <FormGroup>
                          {mealResponse.meal_items.map((item, i) => (
                            <FormControlLabel
                              key={i}
                              control={
                                <Checkbox
                                  checked={selectedItems[i]}
                                  onChange={(e) => {
                                    const newSelected = [...selectedItems];
                                    newSelected[i] = e.target.checked;
                                    setSelectedItems(newSelected);
                                  }}
                                />
                              }
                              label={item.food_name_alias ?? item.food_name}
                              title={item.food_name_alias ?? item.food_name}
                              sx={{
                                '.MuiFormControlLabel-label': {
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  width: '100px',
                                },
                              }}
                            />
                          ))}
                        </FormGroup>
                        <Button
                          variant="contained"
                          onClick={(e) => {
                            const filteredMeal = {
                              ...mealResponse,
                              meal_items: mealResponse.meal_items.filter((_, i) => selectedItems[i]),
                            };
                            props.handleClick?.(filteredMeal, index, props.addItemSource);
                            setAnchorEl(null);
                            setSelectedMealId(null);
                            setSelectedItems([]);
                          }}
                        >
                          Add Items
                        </Button>
                      </Stack>
                    </Paper>
                  </Popper>
                </ClickAwayListener>
              )}
              <Stack
                direction="row"
                spacing={0.5}
                sx={{
                  position: 'relative',
                  height: '100%',
                  ':hover .hover-content': {
                    opacity: 1,
                  },
                  display: 'flex',
                }}
              >
                {authInfo && mealItemWithPhoto?.meal_photo_id
                  ? (
                    <>
                      <BlobImageHistory
                        photo_id={mealItemWithPhoto.meal_photo_id}
                        access_token={authInfo.access_token}
                        style={{
                          flex: 1,
                          height: props.size == 'small' ? 150 : 200,
                          maxHeight: props.size == 'small' ? 150 : 200,
                          objectFit: 'cover',
                        }}
                      />
                      <Typography
                        className="hover-content"
                        sx={{
                          margin: '0 !important',
                          height: props.size == 'small' ? 150 : 200,
                          padding: 0.5,
                          position: 'absolute',
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          backgroundColor: '#c4d5ee',
                          opacity: 0,
                          transition: 'opacity 0s',
                        }}
                      >
                        <Typography
                          sx={{
                            display: '-webkit-box',
                            '-webkit-line-clamp': '5',
                            '-webkit-box-orient': 'vertical',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {mealResponse.meal_items.map(m => m.food_name_alias ?? m.food_name).join(', ')}
                        </Typography>
                        <br />
                        <em>{mealTime}</em>
                        {props.mealTitles?.[index] && (
                          <>
                            <br />
                            {props.mealTitles[index]}
                          </>
                        )}
                      </Typography>
                    </>
                  )
                  : (
                    <Typography
                      sx={{
                        height: props.size == 'small' ? 150 : 200,
                        padding: 0.5,
                      }}
                    >
                      <Typography
                        sx={{
                          display: '-webkit-box',
                          '-webkit-line-clamp': '5',
                          '-webkit-box-orient': 'vertical',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {mealResponse.meal_items.map(m => m.food_name_alias ?? m.food_name).join(', ')}
                      </Typography>
                      <br />
                      <em>{mealTime}</em>
                      {props.mealTitles?.[index] && (
                        <>
                          <br />
                          {props.mealTitles[index]}
                        </>
                      )}
                    </Typography>
                  )}
              </Stack>
            </MainCard>
          );
        })}
      </Stack>
    </Stack>
  );
};
