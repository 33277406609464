import InnerImageZoom from 'react-inner-image-zoom';

import { type MealQueueItem } from 'apiClients/mpq';
import MainCard from 'components/MainCard';

import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import { Box, Typography } from '@mui/material';
import { useAuthenticatedBlobQuery } from 'components/AuthenticatedImage';
import { config } from 'config';
import { useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';

const getMeta = (url: string, cb: (err: string | Event, img: HTMLImageElement) => void) => {
  const img = new Image();
  img.onload = () => cb('Done', img);
  img.onerror = (err: string | Event) => cb.bind(err);
  img.src = url;
};

export const getMealPhotoUrl = (queue: MealQueueItem, size: 'original' | 'resized') => {
  if (!queue.meal_photo_id) {
    return null;
  }
  return config.API_URL + `/api/meal_photos/${queue.meal_photo_id}/` + (
    size === 'original'
      ? 'original_photo'
      : 'photo'
  );
};

export const MealPhoto = (props: {
  queueItem: MealQueueItem,
  styles?: React.CSSProperties,
}) => {
  const { queueItem } = props;
  const img = useAuthenticatedBlobQuery(getMealPhotoUrl(queueItem, 'original'));

  return (
    <MainCard content={false} sx={props.styles}>
      {queueItem.meal_photo_id && (
        <div>
          {img.error
            ? <div>Image request error: {'' + img.error}</div>
            : img.isLoading
            ? <div>Loading...</div>
            : img.src
            ? <ZoomablePhoto src={img.src} />
            : <div>Unable to display image</div>}
        </div>
      )}
      {queueItem.meal_search_text && (
        <Box sx={{ m: 2 }}>
          <Typography variant="body1">
            <b>NLP text:</b> {queueItem.meal_search_text}
          </Typography>
        </Box>
      )}
    </MainCard>
  );
};

export const ZoomablePhoto = (props: {
  src: string | null | undefined,
  styles?: React.CSSProperties,
}) => {
  const { src } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  // force a re-render when the window size changes
  useWindowSize();

  const [_imgCalcWidth, setImgCalcWidth] = useState<number | null>(null);
  useEffect(() => {
    setImgCalcWidth(null);
    if (!src) {
      return;
    }
    getMeta(src, (err, img) => {
      setImgCalcWidth(img?.naturalWidth);
    });
  }, [src]);

  if (!src) {
    return null;
  }

  const containerWidth = containerRef.current?.offsetWidth ?? 1;
  const imgCalcWidth = _imgCalcWidth ?? 1;

  const zoomScale = imgCalcWidth < containerWidth
    ? (containerWidth / imgCalcWidth) * 1.3
    : 1.3;

  return (
    <MainCard ref={containerRef} content={false} sx={props.styles}>
      <InnerImageZoom
        width={containerWidth}
        src={src}
        zoomSrc={src}
        moveType="drag"
        zoomScale={zoomScale}
      />
    </MainCard>
  );
};
