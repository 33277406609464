import { useCallback, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Drawer from 'layout/MainLayout/Drawer';
import Header from 'layout/MainLayout/Header';

import { useMenuBar } from 'services/MenuBarService';
import { NavItemType, NavItemTypeType } from 'types/menu';
import { CapabilitiesOverrideDrawer } from './MainLayout/Header/HeaderContent/Profile/CapabilitiesOverrideDrawer';
import { useNavbarMenuItems } from './NavbarMenuItems';

export const Page = ({ fullWidth = false }: { fullWidth?: boolean }) => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
  const { drawerOpen, setDrawerOpen } = useMenuBar();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    setDrawerOpen(!matchDownLG);
  }, [matchDownLG, setDrawerOpen]);

  const customLocation = location.pathname;

  const getSelectedNavbarItem = useCallback((menu: NavItemType): NavItemType | null => {
    if (menu.children) {
      for (const child of menu.children) {
        if (child.type === 'collapse') {
          const found = getSelectedNavbarItem(child as { children: NavItemType[], type?: NavItemTypeType });
          if (found) {
            return found;
          }
        } else if (child.type === 'item') {
          const childUrlPath = child.url?.split('?')[0];
          if (customLocation === childUrlPath) {
            return child;
          }
        }
      }
    }
    return null;
  }, [customLocation]);

  const navbarMenuItems = useNavbarMenuItems();
  const currentNavbarItem = useMemo(() => {
    if (!navbarMenuItems?.items) {
      return null;
    }

    for (const menu of navbarMenuItems.items) {
      if (menu.type === 'group') {
        const found = getSelectedNavbarItem(menu as { children: NavItemType[], type?: NavItemTypeType });
        if (found) {
          return found;
        }
      }
    }
    return null;
  }, [navbarMenuItems, getSelectedNavbarItem]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
      <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
        <Container
          maxWidth={fullWidth ? false : 'xl'}
          sx={{
            px: { xs: 0, sm: 2 },
            py: { xs: 6 },
            position: 'relative',
            minHeight: 'calc(100vh - 110px)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {currentNavbarItem && <Typography variant="h2">{currentNavbarItem.title}</Typography>}
          <Outlet />
        </Container>
      </Box>
      <CapabilitiesOverrideDrawer open={drawerOpen} />
    </Box>
  );
};
